import React, { FC } from "react";
import { Layout } from "../components/layout";
import resume from "../assets/Resume.pdf";
import running from "../images/running_paris.jpg";
import mountain from "../images/climbing.jpg";
import rock from "../images/climbing_nate.jpg";
import canyon from "../images/canyons.jpg";
import car from "../images/car.jpg";
import materhorn from "../images/materhorn.jpg";
import Link from "../components/customLink";

const IndexPage: FC = () => (
  <Layout>
    <h3>
      <a href="https://www.washington.edu/">UW</a> Graduate
    </h3>
    <p>
      Software engineer at <a href="https://www.frontapp.com">Front</a> -
      building a powerful shared inbox for teams to make customer communication
      effortless. We are currently hiring - reach out if you are interested in
      working with me!
    </p>
    <p>
      I love to spend my free time <a href={running}>running</a>,{" "}
      <a href={rock}>rock climbing</a> (that's my brother/#1 climbing partner
      ❤️), <a href={materhorn}>skiing</a>, <a href={mountain}>mountaineering</a>
      , and doing pretty much{" "}
      <a href="https://youtu.be/zCbxOHH2TVQ">anything</a> <a href={car}>else</a>{" "}
      <a href={canyon}>outside</a> 🏔️🚲
    </p>
    <p>
      I was looking for a new place to store various links I like that had been
      in my bookmarks forever, you can find them <Link to="/links/">here</Link>
    </p>
    <p>
      After a recent trip with a <a href="http://patrickspieker.com/">friend</a>{" "}
      I thought it would be fun to add a map of all the countries I've visited
      to my site - you can see an initial version of it{" "}
      <Link to="/travel">here!</Link>
    </p>
    <p>Contact me: trevor@tshibley.com</p>
    <a href={resume}>
      <p>Resumé</p>
    </a>
  </Layout>
);

export default IndexPage;
